import { css } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { IconButton, Skeleton } from '@mui/material';
import _ from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

import { extractYear, formatUserName, processFilestackUrlSrcSet } from '@jebel/utils';

import { AvatarImageInput, Box, Icon } from 'shared/components/ui';
import { CurrentUserFragment } from 'shared/graphql/__generated__';

import { FileInputWrap } from '../../../../shared/components/ui/FileInputWrap';
import { useResponsive, useUserUpdate } from '../../../../shared/hooks';

const USER_AVATAR_SIZE = 90;

const avatarCss = css`
  width: ${USER_AVATAR_SIZE}px;
  height: ${USER_AVATAR_SIZE}px;
`;

const userNameCss = css`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const avatarIconsContainerCss = theme => css`
  margin: ${theme.spacing(0.75)}px ${theme.spacing(2.5)}px;
`;

const avatarIconsCss = (theme: any) => css`
  color: ${theme.palette.secondary.light};
`;

const containerCss = (isMobile: boolean) => (theme: any) => css`
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  ${!isMobile && `border: 1px solid ${theme.palette.border};`}
`;

const userNameContainerCss = css`
  display: grid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 20px;
`;

const userGraduatingYearCss = (theme: any) => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-column-gap: 4px;
  color: ${theme.palette.text.light};
`;

export const SettingsMobileNavbarUserProfile = ({
  user,
  loading: loadingUser,
}: {
  user: CurrentUserFragment | undefined | null;
  loading: boolean;
}) => {
  const { isMobile } = useResponsive();
  const { onUserUpdate, loading } = useUserUpdate({
    id: user?.id,
  });

  const userGraduatingYear = useMemo(
    () => extractYear(user?.graduatingYear),
    [user?.graduatingYear],
  );

  const fullname = useMemo(() => formatUserName(user), [user]);

  const handleUpdateUserAvatar = async (image: any) => {
    if (!image) {
      await onUserUpdate({
        avatar: {
          disconnect: {
            id: user?.avatar?.id,
          },
        },
      });
      return;
    }
    await onUserUpdate({
      avatar: {
        create: {
          fileId: image.fileId,
          filename: image.filename,
          public: image.public,
        },
      },
    });
  };

  const onPickImage = (pick: any) => {
    pick({ accept: 'image/*', fromSources: ['local_file_system'] });
  };

  if (loadingUser) {
    return (
      <Box css={containerCss(isMobile)}>
        <Skeleton variant="circular" width={USER_AVATAR_SIZE} height={USER_AVATAR_SIZE} />
        <Box display="flex">
          <Skeleton
            width={30}
            height={30}
            css={css`
              margin: 5px;
            `}
          />
          <Skeleton
            width={30}
            height={30}
            css={css`
              margin: 5px;
            `}
          />
        </Box>
        <Box css={userNameContainerCss}>
          <Skeleton width={100} />
        </Box>
        <Box css={userNameContainerCss}>
          <Skeleton width={100} />
        </Box>
      </Box>
    );
  }

  return (
    <Box css={containerCss(isMobile)}>
      <AvatarImageInput
        src={user?.avatar?.downloadUrl || ''}
        srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
          compress: true,
          resize: {
            width: USER_AVATAR_SIZE,
            height: USER_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        css={avatarCss}
        loading={loading}
        onChange={handleUpdateUserAvatar}
      />
      <Box css={avatarIconsContainerCss}>
        {/* TODO: replace with the image editor */}
        <FileInputWrap onChange={handleUpdateUserAvatar}>
          {({ pick }) => {
            return (
              <IconButton size="small" onClick={() => onPickImage(pick)}>
                <Icon name="FileUpload" css={avatarIconsCss} variant="filled" fontSize="small" />
              </IconButton>
            );
          }}
        </FileInputWrap>

        <IconButton size="small" onClick={() => handleUpdateUserAvatar(null)}>
          <Icon name="Clear" css={avatarIconsCss} variant="filled" fontSize="small" />
        </IconButton>
      </Box>
      <Box css={userNameContainerCss}>
        <Typography
          css={userNameCss}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {fullname}
        </Typography>
      </Box>
      {!_.isNaN(userGraduatingYear) && (
        <Box css={userGraduatingYearCss}>
          <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
            CLASS OF {userGraduatingYear}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
