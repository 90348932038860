import { Box } from '@material-ui/core';
import React from 'react';

import { APP_URL } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';

import { SettingsMobileNavbarList } from './SettingsMobileNavbarList';
import { SettingsMobileNavbarUserProfile } from './SettingsMobileNavbarUserProfile';

const MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: APP_URL.user.settings.information,
  },
  {
    title: 'Sign-in & Security',
    icon: 'Lock',
    route: APP_URL.user.settings.security,
  },
];

const ADDITIONAL_SIDEBAR_LIST_DATA = [
  { title: 'Groups', icon: 'People', route: APP_URL.user.settings.groups },
  {
    title: 'Edit Public Profile',
    icon: 'Assignment',
    route: APP_URL.user.settings.profile,
  },
];

const ADMIN_MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: APP_URL.admin.settings.information,
  },
];

export const SettingsMobileNavbar = ({ isAdminRoute = false }: { isAdminRoute: boolean }) => {
  const { user, loading: loadingUser } = useCurrentUser();

  return (
    <Box>
      <SettingsMobileNavbarUserProfile user={user} loading={loadingUser} />

      <SettingsMobileNavbarList
        listData={
          isAdminRoute
            ? ADMIN_MAIN_SIDEBAR_LIST_DATA
            : [...MAIN_SIDEBAR_LIST_DATA, ...ADDITIONAL_SIDEBAR_LIST_DATA]
        }
      />
    </Box>
  );
};
