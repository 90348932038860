import { Theme, css } from '@emotion/react';
import { Box, Paper } from '@material-ui/core';
import { Redirect, Switch } from 'react-router-dom';

import { RouteLayout } from 'shared/components/layouts';
import { Route, Typography } from 'shared/components/ui';
import { ROUTES } from 'shared/constants';
import { SearchProvider } from 'shared/features/search';
import { useResponsive } from 'shared/hooks';

import { SettingsMobileNavbar } from '../components/SettingsMobileNavbar';
import { SettingsSidebar } from '../components/SettingsSidebar';
import { GroupsSettingPage } from './GroupsSettingPage';
import { PublicProfileSettingPage } from './PublicProfileSettingPage';
import { SecuritySettingPage } from './SecuritySettingPage';
import { SettingsAccountInformationPage } from './SettingsAccountInformationPage';

const contentCSS = (theme: Theme) => css`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up('md')} {
    display: grid;
    min-height: 70vh;
    grid-template-columns: 1fr 4fr;
  }
`;

const titleCss = (theme: Theme) => css`
  padding: 0 ${theme.spacing(3.75)}px ${theme.spacing(3.125)}px;
`;

export function SettingsPage() {
  const { isMobile } = useResponsive();

  return (
    <RouteLayout
      withMaxWidth
      maxWidth="100%"
      content={
        <Box display="flex" flexDirection="column">
          <Typography css={titleCss} variant="subtitle2" color="primary">
            My Account
          </Typography>

          <Paper css={contentCSS}>
            {isMobile ? (
              <SettingsMobileNavbar isAdminRoute={false} />
            ) : (
              <SettingsSidebar isAdminRoute={false} />
            )}

            <Switch>
              <Route
                path={ROUTES.user.settings.information}
                title="Account Information"
                authAccess="any"
                exact
              >
                <SettingsAccountInformationPage />
              </Route>

              <Route path={ROUTES.user.settings.security} title="Security" authAccess="any" exact>
                <SecuritySettingPage />
              </Route>

              <Route path={ROUTES.user.settings.groups} title="Groups" authAccess="any" exact>
                <SearchProvider>
                  <GroupsSettingPage />
                </SearchProvider>
              </Route>

              <Route
                path={ROUTES.user.settings.profile}
                title="Public Profile"
                authAccess="any"
                exact
              >
                <PublicProfileSettingPage />
              </Route>

              <Redirect to={ROUTES.user.settings.information} />
            </Switch>
          </Paper>
        </Box>
      }
    />
  );
}
