import { FileValue } from '@8base-react/file-input';
import { css, Theme } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useMemo } from 'react';

import { extractYear, formatUserName, processFilestackUrlSrcSet } from '@jebel/utils';

import { AvatarImageInput, Box } from 'shared/components/ui';
import { CurrentUserFragment } from 'shared/graphql';
import { useUserUpdate } from 'shared/hooks';

const USER_AVATAR_SIZE = 90;

const avatarCss = css`
  width: ${USER_AVATAR_SIZE}px;
  height: ${USER_AVATAR_SIZE}px;
`;

const userNameCss = css`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const containerCss = (theme: Theme) => css`
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.palette.divider};
`;

const userNameContainerCss = css`
  display: grid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 20px;
`;

const userGraduatingYearCss = (theme: Theme) => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-column-gap: 4px;
  color: ${theme.palette.text.light};
`;

export const SettingsSidebarUserProfile = ({
  user,
  loading: loadingUser,
}: {
  user: CurrentUserFragment | undefined | null;
  loading: boolean;
}) => {
  const { onUserUpdate, loading } = useUserUpdate({
    id: user?.id,
  });

  const userGraduatingYear = useMemo(
    () => extractYear(user?.graduatingYear),
    [user?.graduatingYear],
  );

  const fullname = useMemo(() => formatUserName(user), [user]);

  const handleUpdateUserAvatar = async (image: FileValue | null) => {
    if (!image) {
      await onUserUpdate({
        avatar: {
          disconnect: {
            id: user?.avatar?.id,
          },
        },
      });
      return;
    }

    await onUserUpdate({
      avatar: {
        create: {
          fileId: image.fileId,
          filename: image.filename,
          public: true,
        },
      },
    });
  };

  if (loadingUser) {
    return (
      <Box css={containerCss}>
        <Skeleton variant="circle" width={USER_AVATAR_SIZE} height={USER_AVATAR_SIZE} />
        <Box css={userNameContainerCss}>
          <Skeleton width={100} />
        </Box>
        <Box css={userNameContainerCss}>
          <Skeleton width={100} />
        </Box>
      </Box>
    );
  }

  return (
    <Box css={containerCss}>
      <AvatarImageInput
        src={user?.avatar?.downloadUrl || ''}
        srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
          compress: true,
          resize: {
            width: USER_AVATAR_SIZE,
            height: USER_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        css={avatarCss}
        loading={loading}
        onChange={handleUpdateUserAvatar}
      >
        <Person />
      </AvatarImageInput>

      <Box css={userNameContainerCss}>
        <Typography
          css={userNameCss}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {fullname}
        </Typography>
      </Box>

      {Boolean(userGraduatingYear) && (
        <Box css={userGraduatingYearCss}>
          <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
            CLASS OF {userGraduatingYear}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
