import { css, Theme } from '@emotion/react';

import { Box } from 'shared/components/ui';
import { APP_URL, ROUTES } from 'shared/constants';
import { useCurrentUser } from 'shared/hooks';

import { SettingsSidebarList } from './SettingsSidebarList';
import { SettingsSidebarUserProfile } from './SettingsSidebarUserProfile';

const containerCss = (theme: Theme) => css`
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: ${theme.palette.divider};
`;

const MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: APP_URL.user.settings.information,
  },
  {
    title: 'Sign-in & Security',
    icon: 'Lock',
    route: APP_URL.user.settings.security,
  },
];

const ADDITIONAL_SIDEBAR_LIST_DATA = [
  { title: 'Groups', icon: 'People', route: APP_URL.user.settings.groups },
  {
    title: 'Edit Public Profile',
    icon: 'Assignment',
    route: APP_URL.user.settings.profile,
  },
];

const ADMIN_MAIN_SIDEBAR_LIST_DATA = [
  {
    title: 'Account Information',
    icon: 'AccountCircle',
    route: ROUTES.user.settings.information,
  },
];

export const SettingsSidebar = ({ isAdminRoute = false }: { isAdminRoute: boolean }) => {
  const { user, loading: loadingUser } = useCurrentUser();

  return (
    <Box css={containerCss}>
      <SettingsSidebarUserProfile user={user} loading={loadingUser} />

      <SettingsSidebarList
        listData={isAdminRoute ? ADMIN_MAIN_SIDEBAR_LIST_DATA : MAIN_SIDEBAR_LIST_DATA}
      />

      {!isAdminRoute && <SettingsSidebarList listData={ADDITIONAL_SIDEBAR_LIST_DATA} />}
    </Box>
  );
};
