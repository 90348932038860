import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { FieldValidator, useField, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import pluralize from 'pluralize';
import { HTMLAttributes, ReactNode, useCallback, useMemo, useState } from 'react';

import { composeFieldValidators } from '@jebel/utils';

import { getIsInvalid } from 'shared/components/ui';
import { required } from 'shared/utils/form';
import { recordError } from 'shared/utils/record';

import { ListOptionContainer, ListOptionText } from './FormSelectDataSource.styles';

/**
 * Strategies available to manage the unknown values where:
 * - `allow`: Allows single or multiple unknown values mix with the fetched options.
 * - `only`: Only allow to select or create unknown values ignoring the options.
 * - `none`: Only allow the given options restricting to select on the list.
 */
export type UnknownStrategy = 'allow' | 'only' | 'none';

type CustomizableProps = Pick<
  TextFieldProps,
  'label' | 'placeholder' | 'variant' | 'disabled' | 'helperText'
>;

type Identifier = string | number;
type Valuable = object;

type FetchingProps<T extends Valuable> = CustomizableProps & {
  className?: string;

  /** Allow to select multiple elements at the same time. */
  multiple?: boolean;

  /**
   * Allow to add unknown names to the selected list.
   * @default "none"
   */
  allowUnknown?: UnknownStrategy;

  /**
   * Shows some initial options before start typing.
   * @default true
   */
  showSuggestions?: boolean;

  labelSearching?: string;

  name: string;

  validate?: FieldValidator;

  /**
   * Whether the field is required or not.
   * @default false
   */
  required?: boolean;

  extractLabel?(option: T): string;
  extractIdentifier(option: T): Identifier;

  fetchOptions(search: string): Promise<T[]> | T[];
  filterOptions?(options: T[]): T[];

  children?(params: HTMLAttributes<HTMLLIElement>, option: T): ReactNode;
};

interface MultipleKnownProps<T extends Valuable> extends FetchingProps<T> {
  multiple: true;
  allowUnknown?: 'none';

  value?: Array<T>;
  onChange?(value: Array<T>): void;
}

interface MultipleAllowUnknownProps<T extends Valuable> extends FetchingProps<T> {
  multiple: true;
  allowUnknown: 'allow';

  value?: Array<string | T>;
  fetchOptions(search: string): Promise<T[]> | T[];
  onChange?(value: Array<string | T>): void;
}

interface MultipleOnlyUnknownProps<T extends Valuable> extends FetchingProps<T> {
  multiple: true;
  allowUnknown: 'only';

  value?: string[];
  onChange?(value: string[]): void;
}

interface SingleKnownProps<T extends Valuable> extends FetchingProps<T> {
  multiple?: false;
  allowUnknown?: 'none';

  value?: string | T;
  onChange?(value: string | T): void;
}

interface SingleAllowUnknownProps<T extends Valuable> extends FetchingProps<T> {
  multiple?: false;
  allowUnknown: 'allow';

  value?: string | T;
  onChange?(value: string | T): void;
}

interface SingleOnlyUnknownProps<T extends Valuable> extends FetchingProps<T> {
  multiple?: false;
  allowUnknown: 'only';

  value?: string;
  onChange?(value: string | undefined): void;
}

/** Props for the `FormSelectDataSource` component. */
export type Props<T extends Valuable> =
  | MultipleKnownProps<T>
  | MultipleAllowUnknownProps<T>
  | MultipleOnlyUnknownProps<T>
  | SingleKnownProps<T>
  | SingleAllowUnknownProps<T>
  | SingleOnlyUnknownProps<T>;

type NonDefinitionKeys = 'extractLabel' | 'extractIdentifier' | 'fetchOptions' | 'children';

/** Used to define a component that defines their own fetch options and rendering strategy. */
export type DefinitionProps<T extends Valuable> =
  | Omit<MultipleKnownProps<T>, NonDefinitionKeys>
  | Omit<MultipleAllowUnknownProps<T>, NonDefinitionKeys>
  | Omit<MultipleOnlyUnknownProps<T>, NonDefinitionKeys>
  | Omit<SingleKnownProps<T>, NonDefinitionKeys>
  | Omit<SingleAllowUnknownProps<T>, NonDefinitionKeys>
  | Omit<SingleOnlyUnknownProps<T>, NonDefinitionKeys>;

/** Required search length to start fetching. */
export const MIN_SEARCH_LENGTH_FETCHING = 3;
/** Recommended count of suggestions. */
export const SUGGESTIONS_COUNT = 10;

const FALLBACK_SEARCHING_LABEL = 'Searching...';
const FALLBACK_SEARCH_PLACEHOLDER = 'Type to start searching';

export function FormSelectDataSource<T extends Valuable>(props: Props<T>) {
  type Value = (typeof props)['value'];

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [options, setOptions] = useState<T[]>([]);

  const [field, meta, helpers] = useField<Value>({
    name: props.name,
    validate: composeFieldValidators(props.required ? required : null, props.validate),
  });

  const form = useFormikContext();
  const hasError = useMemo(() => getIsInvalid({ meta, form }), [meta, form]);

  const defaultValue = props.multiple ? [] : '';
  const value: Value = field.value ?? meta.value ?? defaultValue;

  const labelSearching = props.labelSearching ?? FALLBACK_SEARCHING_LABEL;
  const placeholder = props.placeholder ?? FALLBACK_SEARCH_PLACEHOLDER;

  const allowUnknown = props.allowUnknown === 'allow';
  const onlyUnknown = props.allowUnknown === 'only';
  const showSuggestions = props.showSuggestions ?? true;

  const showOptions = !onlyUnknown;
  const freeSoloMode = allowUnknown || onlyUnknown;

  const extractIdentifier = (given: unknown) => {
    if (given && typeof given === 'object') {
      return props.extractIdentifier(given as unknown as T);
    }

    return String(given);
  };

  const selected = useMemo(() => {
    const selected: Identifier[] = [];

    if (!value) {
      return [];
    }

    if (typeof value === 'string') {
      return [value];
    }

    if (!Array.isArray(value)) {
      return [extractIdentifier(value)];
    }

    for (const option of value) {
      selected.push(extractIdentifier(option));
    }

    return selected;
  }, [value]);

  const message = useMemo(() => {
    if (hasError) {
      return meta.error;
    }

    return props.helperText;
  }, [hasError, meta]);

  const noOptionsLabel = useMemo(() => {
    if (typing) {
      return `Please keep typing, we'll start searching when you finish.`;
    }

    if (search.length < MIN_SEARCH_LENGTH_FETCHING) {
      const letters = pluralize('letter', MIN_SEARCH_LENGTH_FETCHING);
      return `Type at least ${MIN_SEARCH_LENGTH_FETCHING} ${letters} to start to searching.`;
    }

    return `No records found with the search "${search}".`;
  }, [value, search, typing]);

  const fetchOptions = async (search: string) => {
    setTyping(false);
    setLoading(true);
    setOptions([]);

    try {
      const response = await props.fetchOptions(search);

      if (Array.isArray(response)) {
        setOptions(response);
      }
    } catch (err) {
      recordError(err);
    }

    setLoading(false);
  };

  const handleFetchOptions = useCallback(debounce(fetchOptions, 500), [setOptions, setLoading]);

  const changeValue = (value: Value) => {
    helpers.setValue(value);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props.onChange?.(value as any);
  };

  const handleChange = (event: unknown, value: unknown) => {
    if (props.multiple && Array.isArray(value)) {
      changeValue(value);
      return;
    }

    if (!value) {
      changeValue(undefined);
      return;
    }

    if (typeof value === 'object' || typeof value === 'string') {
      changeValue(value as unknown as T);
    }
  };

  const handleSearch = (event: unknown, search: string) => {
    setTyping(true);
    setSearch(search);

    if (!showSuggestions && search.length < MIN_SEARCH_LENGTH_FETCHING) {
      return;
    }

    if (showOptions) {
      handleFetchOptions(search);
    }
  };

  const handleFilerOptions = (given: unknown[]) => {
    const options: T[] = props.filterOptions?.(given as T[]) ?? (given as T[]);
    const filtered: unknown[] = [];

    if (!loading && freeSoloMode && search) {
      filtered.push(search);
    }

    for (const option of options) {
      const isSelected = selected.some(
        identifier => identifier === props.extractIdentifier(option),
      );

      if (isSelected) {
        continue;
      }

      filtered.push(option);
    }

    return filtered;
  };

  const handleSearchFocus = () => {
    if (showSuggestions && showOptions && options.length === 0) {
      // Allow to fetch an initial stack of options to display without any search
      fetchOptions('');
    }
  };

  const handleSearchBlur = () => {
    if (freeSoloMode) {
      return;
    }

    handleSearch(null, '');
  };

  const formatOptionLabel = (value: unknown) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return String(value);
    }

    if (value && typeof props.extractLabel === 'function') {
      return props.extractLabel(value as T);
    }

    return '(Unknown)';
  };

  const checkOptionEquals = (given: unknown, selected: unknown) => {
    if (selected === '') {
      return true;
    }

    const givenIdentifier = extractIdentifier(given);
    const selectedIdentifier = extractIdentifier(selected);

    return givenIdentifier === selectedIdentifier;
  };

  const renderOption = (params: HTMLAttributes<HTMLLIElement>, given: unknown) => {
    delete params.className;

    if (typeof given === 'string' || typeof given === 'number') {
      return (
        <ListOptionContainer {...params} key={given}>
          <ListOptionText>Add &quot;{given}&quot;</ListOptionText>
        </ListOptionContainer>
      );
    }

    if (typeof props.children === 'function') {
      return props.children(params, given as T);
    }

    if (typeof props.extractLabel === 'function') {
      return props.extractLabel(given as T);
    }

    return null;
  };

  return (
    <Autocomplete
      freeSolo={freeSoloMode}
      options={options}
      value={value}
      inputValue={search}
      noOptionsText={noOptionsLabel}
      loadingText={labelSearching}
      filterOptions={handleFilerOptions}
      onChange={handleChange}
      onInputChange={handleSearch}
      onFocus={handleSearchFocus}
      getOptionLabel={formatOptionLabel}
      isOptionEqualToValue={checkOptionEquals}
      clearOnBlur={false}
      includeInputInList={true}
      multiple={props.multiple}
      className={props.className}
      loading={loading}
      renderOption={renderOption}
      renderInput={params => (
        <TextField
          {...params}
          variant={props.variant}
          placeholder={placeholder}
          label={props.label}
          onChange={undefined}
          disabled={params.disabled || props.disabled}
          error={hasError}
          helperText={message}
          required={props.required}
          onBlur={handleSearchBlur}
        />
      )}
    />
  );
}
