import { css, Theme } from '@emotion/react';
import { Typography } from '@material-ui/core';
import { Avatar } from '@mui/material';
import React, { useMemo } from 'react';

import { extractYear, formatUserName, processFilestackUrlSrcSet } from '@jebel/utils';

import { Box, Icon } from 'shared/components/ui';
import { CurrentUserFragment } from 'shared/graphql';

const USER_AVATAR_SIZE = 90;

const avatarCss = css`
  width: ${USER_AVATAR_SIZE}px;
  height: ${USER_AVATAR_SIZE}px;
`;

const userNameCss = css`
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const editIconCss = css`
  font-size: 16px;
`;

const containerCss = (theme: Theme) => css`
  display: grid;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${theme.palette.divider};
`;

const userNameContainerCss = css`
  display: grid;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 20px;
`;

const userGraduatingYearCss = (theme: Theme) => css`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-column-gap: 4px;
  color: ${theme.palette.text.light};
`;

interface MemberSidebarUserProfileProps {
  user: CurrentUserFragment | undefined | null;
}

export const MemberSidebarUserProfile: React.FC<MemberSidebarUserProfileProps> = ({ user }) => {
  const userGraduatingYear = useMemo(
    () => extractYear(user?.graduatingYear),
    [user?.graduatingYear],
  );

  const fullname = useMemo(() => formatUserName(user), [user]);

  return (
    <Box css={containerCss}>
      <Avatar
        css={avatarCss}
        srcSet={processFilestackUrlSrcSet(user?.avatar?.downloadUrl || '', {
          compress: true,
          resize: {
            width: USER_AVATAR_SIZE,
            height: USER_AVATAR_SIZE,
            fit: 'crop',
          },
        })}
        src={user?.avatar?.downloadUrl || ''}
        variant="circular"
      />
      <Box css={userNameContainerCss}>
        <Typography
          css={userNameCss}
          variant="inherit"
          align="inherit"
          color="initial"
          paragraph={false}
        >
          {fullname}
        </Typography>
      </Box>

      {Boolean(userGraduatingYear) && (
        <Box css={userGraduatingYearCss}>
          <Typography variant="inherit" align="inherit" color="initial" paragraph={false}>
            CLASS OF {userGraduatingYear}
          </Typography>
          <Icon
            css={editIconCss}
            name="Edit"
            variant="filled"
            color="inherit"
            fontSize="small"
            viewBox="0 0 24 24"
          />
        </Box>
      )}
    </Box>
  );
};
