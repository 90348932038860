import { css } from '@emotion/react';
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  IconButton,
  MenuItem,
  Radio,
} from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { FieldArray, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { isEmpty, omit } from 'ramda';
import { Fragment, useMemo } from 'react';

import { SettingsContentLayout } from 'shared/components/layouts';
import {
  Form,
  FormTextField,
  FormSelect,
  FormPhoneFieldV2 as FormPhoneField,
  FormKeyboardDatePicker,
  FormRadioGroup,
  AutocompleteField,
} from 'shared/components/ui';
import { US_STATES_OPTIONS, FIELDS_CONFIG_USER } from 'shared/constants';
import { UserUpdateInput, UsersUserPreferencesUpdateRelationInput } from 'shared/graphql';
import { useCurrentUser, useResponsive, useToast, useUserUpdate } from 'shared/hooks';
import { useUserById } from 'shared/hooks/useUserById';
import { required } from 'shared/utils/form';
import { omitDeep } from 'shared/utils/object';
import { recordError } from 'shared/utils/record';

const formCSS = css`
  display: grid;
  grid-gap: 20px;
`;

const rowLayoutCss = (isMobile: boolean) => css`
  display: grid;
  grid-gap: 20px;
  ${isMobile ? `grid-template-rows: 1fr 1fr;` : `grid-template-columns: 1fr 1fr;`}
`;

type Parent = {
  fullName: string;
  classYear?: string | null;
};

type FormData = {
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  gender?: string | null | undefined;
  affiliation?: string | null | undefined;
  graduatingYear?: string | null | undefined;
  birthDate?: DateTime | null | undefined;
  parents?: Array<Parent>;
  description?: string | null | undefined;
  isParentsAvailable?: boolean;
  userPreferences?:
    | {
        phoneNumber?: string | null | undefined;
        secondaryPhoneNumber?: string | null | undefined;
        address?:
          | {
              zip?: string | null | undefined;
              city?: string | null | undefined;
              state?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

const UPDATING_MESSAGE_KEY = 'UPDATING_MESSAGE_KEY';

export function SettingsAccountInformationPage() {
  const { showError, showMessage, dismiss } = useToast();
  const { isMobile } = useResponsive();
  const { user, loading } = useCurrentUser();
  const { onUserUpdate } = useUserUpdate({ id: user?.id });

  const initialValues = useMemo<FormData>(() => {
    const userData = omitDeep(
      [
        '__typename',
        'id',
        'fullName',
        'roles',
        'joinedGroups',
        'ownedOrganizations',
        'schoolConfigurations',
        'rolesAddons',
        'avatar',
        'createdGroups',
        'viewedThreads',
        'adminPreferences',
        'managerPreferences',
        'education',
      ],
      user,
    );

    const initialParents = [
      {
        fullName: '',
        classYear: '',
      },
    ];

    const birthDate = DateTime.fromSQL(user?.birthDate);

    const payload: FormData = {
      ...userData,

      birthDate: null,
      parents: isEmpty(userData?.parents || []) ? initialParents : userData?.parents,
      isParentsAvailable: !isEmpty(userData?.parents),
      userPreferences: {
        ...userData?.userPreferences,
        phoneNumber: userData?.userPreferences?.phoneNumber?.length
          ? userData?.userPreferences?.phoneNumber
          : '',
        secondaryPhoneNumber: userData?.userPreferences?.secondaryPhoneNumber?.length
          ? userData?.userPreferences?.secondaryPhoneNumber
          : '',
      },
    };

    if (birthDate.isValid) {
      payload.birthDate = birthDate;
    }

    return payload;
  }, [user]);

  const onSubmit = async (form: FormData) => {
    try {
      const birthDate = form.birthDate?.endOf('day');
      const parents = form.isParentsAvailable ? form.parents : [];

      const userPreferences: UsersUserPreferencesUpdateRelationInput = {
        create: {
          phoneNumber: form.userPreferences?.phoneNumber,
          secondaryPhoneNumber: form.userPreferences?.secondaryPhoneNumber,

          address: {
            create: form.userPreferences?.address,
          },
        },
      };

      const data: UserUpdateInput = {
        ...omit(['birthDate', 'parents', 'isParentsAvailable'], form),

        birthDate: null,
        userPreferences,
        parents,
      };

      if (birthDate?.isValid) {
        data.birthDate = birthDate.toSQLDate();
      }

      showMessage('Updating your account information.', { id: UPDATING_MESSAGE_KEY });

      await onUserUpdate(data);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      dismiss(UPDATING_MESSAGE_KEY);
    }
  };

  const onResetForm = (resetForm: FormikProps<FormData>['resetForm']) => () => {
    resetForm();
  };

  return (
    <SettingsContentLayout title="Account Information" isLoading={loading}>
      <Form<FormData>
        oldCss={formCSS}
        validateOnChange={false}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, dirty, resetForm }) => (
          <Fragment>
            <Box css={rowLayoutCss(isMobile)}>
              <FormTextField
                inputProps={{
                  label: 'First Name',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'firstName', validate: required }}
              />

              <FormTextField
                inputProps={{
                  label: 'Last Name',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'lastName', validate: required }}
              />
            </Box>

            <FormHelperText>
              To add a NICK NAME or MIDDLE NAME use the First Name field: John “Nickname” or John
              Middle.
            </FormHelperText>

            <Box css={rowLayoutCss(isMobile)}>
              <FormPhoneField
                inputProps={{
                  label: 'Primary Phone Number',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'userPreferences.phoneNumber' }}
              />

              <FormPhoneField
                inputProps={{
                  label: 'Secondary Phone Number',
                }}
                fieldProps={{ name: 'userPreferences.secondaryPhoneNumber' }}
              />
            </Box>

            <Box css={rowLayoutCss(isMobile)}>
              <FormSelect
                selectProps={{
                  children: FIELDS_CONFIG_USER.gender.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'Gender',
                }}
                fieldProps={{ name: 'gender', validate: required }}
              />
              <FormSelect
                selectProps={{
                  children: FIELDS_CONFIG_USER.affiliation.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )),
                  variant: 'outlined',
                  label: 'Affiliation',
                }}
                fieldProps={{ name: 'affiliation', validate: required }}
              />

              {values.affiliation === 'alumni' && (
                <FormSelect
                  selectProps={{
                    children: FIELDS_CONFIG_USER.graduatingYear.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )),
                    variant: 'outlined',
                    label: 'Graduation Year',
                  }}
                  fieldProps={{ name: 'graduatingYear', validate: required }}
                />
              )}

              {values.affiliation === 'other' && (
                <FormTextField
                  inputProps={{
                    label: 'Affiliation Description',
                    variant: 'outlined',
                  }}
                  fieldProps={{ name: 'affiliationDescription', validate: required }}
                />
              )}
            </Box>

            <Box css={rowLayoutCss(isMobile)}>
              <FormRadioGroup
                label="Parent (current student or alumni)?"
                fieldProps={{
                  name: 'isParentsAvailable',
                  onBeforeChange: value => value === 'true',
                }}
              >
                <Box display="flex" gridGap="0.5rem">
                  <FormControlLabel
                    control={<Radio color="secondary" value="true" />}
                    label="Yes"
                  />

                  <FormControlLabel
                    control={<Radio color="secondary" value="false" />}
                    label="No"
                  />
                </Box>
              </FormRadioGroup>
            </Box>

            {values.isParentsAvailable && (
              <FieldArray
                name="parents"
                render={arrayHelpers => {
                  return (
                    <Fragment>
                      {values.parents?.map((parent, index: number) => (
                        <Box css={rowLayoutCss(isMobile)} key={index}>
                          <FormTextField
                            fieldProps={{
                              validate: required,
                              name: `parents.${index}.fullName`,
                            }}
                            inputProps={{
                              label: 'Full Name',
                              variant: 'outlined',
                            }}
                          />
                          <Box
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr auto',
                              alignItems: 'center',
                              gap: '8px',
                            }}
                          >
                            <FormSelect
                              selectProps={{
                                children: FIELDS_CONFIG_USER.graduatingYear.map(item => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                )),
                                variant: 'outlined',
                                label: 'Class Year',
                              }}
                              fieldProps={{
                                name: `parents.${index}.classYear`,
                                validate: required,
                              }}
                            />

                            <IconButton
                              disabled={index === 0}
                              onClick={() => arrayHelpers.remove(index)}
                              size="small"
                              style={{
                                height: 'min-content',
                              }}
                            >
                              <Remove />
                            </IconButton>
                          </Box>
                        </Box>
                      ))}

                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => arrayHelpers.push('')}
                        startIcon={<Add />}
                      >
                        Add another
                      </Button>
                    </Fragment>
                  );
                }}
              />
            )}
            <Box css={rowLayoutCss(isMobile)}>
              <FormKeyboardDatePicker
                datePickerProps={{
                  allowKeyboardControl: true,
                  disableFuture: true,
                  inputVariant: 'outlined',
                  label: 'Date of birth',
                  variant: 'inline',
                }}
                fieldProps={{ name: 'birthDate' }}
              />
              <FormTextField
                inputProps={{
                  label: 'ZIP Code',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'userPreferences.address.zip' }}
              />
              <FormTextField
                inputProps={{
                  label: 'City',
                  variant: 'outlined',
                }}
                fieldProps={{ name: 'userPreferences.address.city' }}
              />

              <AutocompleteField
                name="userPreferences.address.state"
                label="State"
                options={US_STATES_OPTIONS}
              />
            </Box>

            <Box display="flex" justifyContent="end" gridGap="1rem">
              <Button
                disabled={!dirty || isSubmitting}
                variant="outlined"
                onClick={onResetForm(resetForm)}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                disableElevation
                variant="contained"
                type="submit"
                disabled={!dirty || isSubmitting}
              >
                Save changes
              </Button>
            </Box>
          </Fragment>
        )}
      </Form>
    </SettingsContentLayout>
  );
}
